import * as React from 'react';
import { Helmet } from "react-helmet"

import { StaticImage } from 'gatsby-plugin-image';

import { Email } from "react-obfuscate-email";


import {
  SingleImageContent,
  ContentText,
  UlContact,
  UlSocial,
  DesktopOnly,
  Credit,
  StyledILoveH04,
} from "../styles/styles";

// markup
const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>
          Hall 04 et Compagnie - Creation et intégration de mobilier urbain et
          skateparks
        </title>
        <meta
          name="description"
          content={`Hall04 Et CIE création de skateparks et mobilier urbain attractifs, innovants et intégrés à l'environement attenant. Hall04 ce sont des skateparks crées par des usagers avec plus de ${
            new Date().getFullYear() - 2000
          } ans d'expérience.`}
        />
        <meta
          property="og:title"
          content="Hall 04 et Compagnie - Creation et intégration de mobilier urbain et
          skateparks"
        />
        <meta
          property="og:description"
          content={`Hall04 Et CIE création de skateparks et mobilier urbain attractifs, innovants et intégrés à l'environement attenant. Hall04 ce sont des skateparks crées par des usagers avec plus de ${
            new Date().getFullYear() - 2000
          } ans d'expérience.`}
        />
        <meta property="og:image" content="../images/i<3hall04-tee@2x.png" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="fr_FR" />
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://hall04.net" />
      </Helmet>
      <SingleImageContent>
        <ContentText>
          <h1>/// HALL04 ET CIE ///</h1>
          <UlContact>
            <li>
              <a
                href="tel:0033687247084"
                title="Appelez Hall04 pour des informations sur la construction mis en place d'un skatepark"
              >
                T : 00 (33) 687 247 084
              </a>
            </li>
            <li>
              <Email
                title="Envoyez nous un email pour des informations sur la construction mis en place d'un skatepark"
                email="contact@hall04.net"
              >
                M : contact@hall04.net
              </Email>
            </li>
          </UlContact>
          <UlSocial>
            <li>
              <a
                href="https://instagram.com/Hall04_skateparks"
                target="_blank"
                rel="noopener noreferrer"
                title="Suivez Hall04 Skateparks sur Instagram pour voir toutes nos actualitées photos videos"
              >
                Insta : Hall04_skateparks
              </a>{" "}
              <DesktopOnly>-</DesktopOnly>{" "}
              <a
                href="https://facebook.com/hall04"
                target="_blank"
                rel="noopener noreferrer"
                title="Suivez Hall04 skatepark aussi sur Facebook"
              >
                facebook.com/hall04
              </a>
            </li>
          </UlSocial>
        </ContentText>
        <Credit>
          <p>
            Photo: Pierre Antoine Lalande - Action: Alex Serrer - Location: Pau
            64
          </p>
        </Credit>
        <StyledILoveH04>
          <StaticImage
            loading="eager"
            src="../images/i<3hall04-tee@2x.png"
            alt=""
            layout="fixed"
            width={100}
          />
        </StyledILoveH04>
      </SingleImageContent>
    </main>
  );
};

export default IndexPage;
